<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton :color="filters ? 'dark' : 'warning'" size="sm" @click="toggleFilters" style="height: 26px; padding: 2px 5px;"><CIcon name="cil-filter"/></CButton>
          <CButton color="success" size="sm" @click="importModal()" v-if="type == 'general'">Importar Ventas</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="filters">
        <CCol md="6">
          <div role="group" class="form-group" style="margin: -10px 0px 10px" v-if="type == 'general'">
            <label> Vendedor:</label>
            <CSelect
              :value.sync="supplierSelect" 
              :options="suppliersOptions"
              @change="changeFilters"
            />                        
          </div>
        </CCol>
        <!--
        <CCol md="3">
          <div role="group" class="form-group" style="margin: -10px 0px 10px" v-if="type == 'general'">
            <label> Cliente:</label>
            <CSelect
              :value.sync="customerSelect" 
              :options="customersOptions"
              @change="changeFilters"
            />                        
          </div>
        </CCol>
        <CCol md="3">
          <div role="group" class="form-group" style="margin: -10px 0px 10px" v-if="type == 'general'">
            <label> CEDIS:</label>
            <CSelect
              :value.sync="cedisSelect" 
              :options="cedisOptions"
              @change="changeFilters"
            />                        
          </div>
        </CCol>
        -->
        <CCol md="6">
          <div role="group" class="form-group" style="margin: -10px 0px 10px">
            <label> Rango de Fechas:</label>
            <v-date-picker                    
              v-model="range"    
              class="date-picker"
              locale="es-MX" size="sm"          
              mode="range"
            />        
          </div>
        </CCol>
    </CRow>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="30"
        :dark="dark"
        pagination
      >
        <template #date="{item}"><td class="text-center w100">{{item.date | date}}</td></template>
        <template #cedis_name="{item}"><td class="text-center w100">{{item.cedis_name}}</td></template>
        <template #sname="{item}"><td class="text-left w300">{{item.supplier_code}} - {{item.supplier_name}}</td></template>
        <template #cname="{item}"><td class="text-left w300">{{item.customer_code}} - {{item.customer_name}}<br />{{item.product_name}}</td></template>
        <template #boxes="{item}"><td class="text-right w50">{{item.boxes}}</td></template>
        <template #total="{item}"><td class="text-right w50"><span style="float: left;">$</span> {{item.total | currency}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <ImportSalesModal ref="importModal" @import="importProcess"></ImportSalesModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

import ImportSalesModal from '../sales/ImportSalesModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import customers_ws from '../../services/customers';
import suppliers_ws from '../../services/suppliers';
import cedis_ws from '../../services/cedis';
import ws from '../../services/sales';
import store from '../../store'

export default {
  name: 'ChallengeSales',
  components: { ImportSalesModal, DeleteModal, 'v-date-picker': DatePicker },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      default () {
        return [
          {key: 'date', label: 'Fecha'},
          {key: 'cedis_name', label: 'CEDIS'},
          {key: 'sname', label: 'Vendedor'},
          {key: 'cname', label: 'Cliente'},
          {key: 'boxes', label: 'Cajas'},
          {key: 'total', label: 'Monto'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    type: {
      type: String,
      default: 'general'
    },
    challenge: {
      type: Object,
      default: 0
    },
    reference: 0,
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      filters: false,
      customers: [],
      customerSelect: "",
      customersOptions: [],
      suppliers: [],
      supplierSelect: "",
      suppliersOptions: [],
      cedis: [],
      cedisSelect: "",
      cedisOptions: [],
      range: {
        start: new moment().startOf('month').toDate(),
        end: new moment().endOf("month").toDate(),
      },
      toasts: [],
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  mounted: async function() {
    this.loading();

    let response2 = await suppliers_ws.getByChallenge(this.challenge.id); 

    if(response2.type == "success"){
      this.suppliers = response2.data;
      this.suppliersOptions = await this.$parseSelectOptionsOrdered(this.suppliers, "id", "name",[{"label": "Todos los Vendedores","value":""}]);
    }

    this.loaded();
  },
  methods: {
    toggleFilters () {
      this.filters = !this.filters;
    },
    async changeFilters () {
      this.loading();

      let filters = this.getFilters();
      let response = await ws.getFiltered(filters);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
    },
    getFilters(){
      let filters = {
        date : [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")],
        // customer_id : this.customerSelect,
        supplier_id : this.supplierSelect,
        // cedis_id : this.cedisSelect
      };

      if(this.type == "supplier"){
        filters.supplier_id = this.reference;
        // filters.customer_id = 0;
        // filters.cedis_id = 0;
      }

      // if(this.type == "customer"){
      //   filters.customer_id = this.reference;
      //   filters.supplier_id = 0;
      //   filters.cedis_id = 0;
      // }

      // if(this.type == "cedis"){
      //   filters.cedis_id = this.reference;
      //   filters.supplier_id = 0;
      //   filters.customer_id = 0;
      // }

      return filters;
    },
    importModal () {
      this.$refs.importModal.importModal(this.challenge.id);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Venta", item.id, item.name);
    },
    async importProcess(data) {
      this.$emit("refresh", data );
    },
    
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  },
}
</script>
