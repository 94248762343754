<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            <CRow>
              <CCol :md="form.type != 'file' ? 6 : 8">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.question.$model"
                  :isValid="checkIfValid('question')"
                  placeholder=""
                  autocomplete="given-question"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Tipo: "
                  id="type"
                  name="type"
                  :value.sync="$v.form.type.$model"
                  :options="typeOptions"
                />
              </CCol>
              <CCol md="2" v-if="form.type != 'file'">
                <CSelect
                  label="Abierta: "
                  id="is_open"
                  name="is_open"
                  :value.sync="form.is_open"
                  :options="[
                    {label: 'Sí', value: '1'},
                    {label: 'No', value: '0'}
                  ]"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol :md="form.is_open == '0' ? 8 : 12" v-if="form.type == 'options'">
                <label>Opciones: <sub>(separadas por comas)</sub></label>
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.options.$model"
                  id="options"
                  name="options"
                  :isValid="checkIfValid('options')"
                  placeholder=""
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol v-if="form.is_open == '0' && form.type != 'file'" :md="form.type == 'options' ? 4 : 12">
                <label>Respuesta Correcta:</sub></label>
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.answer.$model"
                  id="answer"
                  name="answer"
                  :isValid="checkIfValid('answer')"
                  placeholder=""
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, requiredIf, helpers } from "vuelidate/lib/validators"
import ws from '../../services/challenges';

export default {
  name: 'QuestionModal',
  props: {
    challenge: {
      type: Object,
      default: 0
    },
  },
  data () {
    return {
      showModal: false,
      title: "Nueva Pregunta",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      typeOptions: [
        {label: 'Texto', value: 'text'},
        {label: 'Opciones', value: 'options'},
        {label: 'Archivo', value: 'file'}
      ],
    }
  },
  mounted: async function() {
    
  },
  methods: {
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nueva Pregunta';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          challenge_id: this.challenge.id,
          question: '',
          answer: '',
          type: 'text',
          is_open: '0',
          options: ''
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Pregunta';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          challenge_id: this.challenge.id,
          question: item.question,
          answer: item.correct_answer,
          type: item.type,
          is_open: item.is_open+"",
          options: item.options,
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          challenge_id: this.challenge.id,
          question: '',
          answer: '',
          type: 'text',
          is_open: '0',
          options: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      question: {
        required,
        minLength: minLength(2)
      },
      answer: {
        required: requiredIf(function(form){
          return form.is_open == '0' && form.type != 'file'
        })
      },
      type: {},
      is_open: {},
      options: {
        required: requiredIf(function(form){
          return form.is_open == '0' && form.type == 'options'
        })
      }
    }
  },
}
</script>