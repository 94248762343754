<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> Dinámica del Concurso ({{challenge.type}})
        <div class="card-header-actions"><CButton color="info" size="sm" v-show="challenge.type == 'video'" @click="storeModal()">Nuevo Video</CButton></div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="challenge.type == 'video'">
        <CCol>
          <b>Paso 1: </b> Para este concurso el participante deberá ver los siguientes videos:
          <br /><br />
          <ul>
            <li v-for="(video, index) in challenge.videos">
              <CRow v-if="challenge.type == 'video'">
                <CCol>
                  <a :href="'https://www.youtube.com/watch?v='+video.youtube_reference" target="_blank">https://www.youtube.com/watch?v={{video.youtube_reference}}</a>
                </CCol>
                <CCol>
                  <CButton color="danger" size="sm" @click="deleteVideoModal(video)" style="margin: 5px;">Eliminar</CButton>
                </CCol>
              </CRow>
            </li>
          </ul>
          <b>Paso 2: </b> Deberás completar el cuestionario con preguntas relacionadas con los videos anteriores, para completar el concurso deberás sacar al menos:
          <br /><br />
          <CSelect
            id="rate"
            name="rate"
            :value.sync="challenge.rate"
            :options="averageOptions"
            style="width: 200px;"
            @change="changeRate()"
          />
        </CCol>
      </CRow>
    </CCardBody>
    <ChallengeVideoModal ref="challengeVideoModalRules" @store="storeProcess"></ChallengeVideoModal>
    <DeleteModal ref="deleteVideoModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import ws from '../../services/challenges';
import store from '../../store'
import router from '../../router/index'
import ChallengeVideoModal from '../../components/challenges/ChallengeVideoModal.vue'
import DeleteModal from '../global/DeleteModal.vue'

export default {
  name: 'ChallengeRules',
  components: { ChallengeVideoModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    challenge: {
      type: Object,
      default: 0
    }
  },
  data() {
    return {
      toasts: [],
      rate: 100,
      averageOptions: [
        {label: '100% de Aciertos', value: 100},
        {label: '95% de Aciertos', value: 95},
        {label: '90% de Aciertos', value: 90},
        {label: '85% de Aciertos', value: 85},
        {label: '80% de Aciertos', value: 80},
        {label: '75% de Aciertos', value: 75},
        {label: '70% de Aciertos', value: 70},
        {label: '65% de Aciertos', value: 65},
        {label: '60% de Aciertos', value: 60},
        {label: '55% de Aciertos', value: 55},
        {label: '50% de Aciertos', value: 50},
      ],
    }
  },
  methods: {
    async changeRate () {
      let params = {challenge_id: this.challenge.id, rate: this.challenge.rate};

      let response = await ws.changeRate(params);
      
      if(response.type == "success"){
        
      }

      this.showToast(response.type, response.message);
    },
    storeModal (item) {
      this.$refs.challengeVideoModalRules.storeModal(this.challenge.id);
    },
    async storeProcess (data) {
      this.$emit("add", data );
    },
    deleteVideoModal (item) {
      this.$refs.deleteVideoModal.show("Eliminar Video", item.id, "https://www.youtube.com/watch?v="+item.youtube_reference);
    },
    async deleteProcess(id) {
      let response = await ws.deleteVideo({video_id: id});
      
      if(response.type == "success"){
        this.$emit("remove", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
