<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Link de Youtube:"
                  :lazy="false"
                  :value.sync="$v.form.youtube.$model"
                  :isValid="checkIfValid('youtube')"
                  placeholder=""
                  autocomplete="given-youtube"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, numeric, minLength, helpers, requiredIf, requiredUnless } from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone';

import c from 'moment-timezone';
import ws from '../../services/challenges';
import store from '../../store'

export default {
  name: 'ChallengeVideoModal',
  props: {

  },
  components: { 'v-date-picker': DatePicker },
  data () {
    return {
      showModal: false,
      title: "Nuevo Concurso",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      typeOptions: [
        {label: 'Capacitación', value: 'video'},
        {label: 'Venta Mínima', value: 'sales'},
        {label: 'Clientes Mínimos', value: 'customers'},
        {label: 'Mínimo de Cajas', value: 'boxes'}
      ],
      link: '',
      item: false
    }
  },
  mounted: async function() {
    
  },
  methods: {
    storeModal (challenge_id) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Video';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          challenge_id: challenge_id,
          youtube: ''
      }
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        console.log(this.form);

        this.$emit("store", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          challenge_id: 0,
          youtube: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    redirectLink(link){
      window.location = link;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      youtube: {
        required
      }
    }
  },
}
</script>